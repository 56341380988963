import {
  Box,
  Button,
  Typography,
  Tooltip,
  Stack,
  Modal
} from "@mui/material";

import {
  useLazyGetProductQuery,
  useGetAddOnQuery,
} from "../redux/api/productApi";
import { FormEvent, useEffect, useState, useContext, useMemo, ChangeEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, useLocation } from "react-router-dom";
import { IPlanDetail } from "../redux/api/types";

import "./pricing.page.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setModule } from "../redux/features/genieSlice";
import { setPayment } from "../redux/features/paymentSlice";
import rating_img from "../assets/traffic_monster/rating_img1.png";
import { useLazyCreateCheckoutSessionQuery } from "../redux/api/subscriptionApi";
import { getDesignTokens, ColorModeContext } from "../theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logo_white from '../assets/logo_white.png';
import { isMobile } from "react-device-detect";
// import img54 from '../assets/newimg/logo.png';
import img54 from '../assets/newimg/logo_ocesha.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { usePlanDetailsMutation } from "../redux/api/productApi";
import { LoadingButton } from "@mui/lab";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeIco1 from '../assets/traffic_monster/youtubeIco_1.png';
import OceshaLogo from '../assets/ocesha_images/ocesha_logo.png';
import { ReactComponent as BlogsIco } from '../assets/ocesha_images/blogs_ico.svg';
import { ReactComponent as VideoIco } from '../assets/ocesha_images/video_ico.svg';
import { ReactComponent as AudioIco } from '../assets/ocesha_images/audio_ico.svg';
import { ReactComponent as ImagesIco } from '../assets/ocesha_images/images_ico.svg';
import { ReactComponent as TeamIco } from '../assets/ocesha_images/team_ico.svg';
import { ReactComponent as IntegrationIco } from '../assets/ocesha_images/integration_ico.svg';
import { ReactComponent as UpgradeIco } from '../assets/ocesha_images/upgrade_ico.svg';
import { ReactComponent as BillingIco } from '../assets/ocesha_images/billing_ico.svg';
import { ReactComponent as SettingsIco } from '../assets/ocesha_images/settings_ico.svg';
import { ReactComponent as HelpIco } from '../assets/ocesha_images/help_ico.svg';
import { ReactComponent as LogoutIco } from '../assets/ocesha_images/logout_ico.svg';
import { ReactComponent as DashboardIco } from '../assets/ocesha_images/dashboard_ico.svg';
import { logout } from "redux/features/userSlice";
import { toast } from "react-toastify";
import { setCookie, getCookie, removeCookie } from '../utils/storageUtils';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ocesha_logo from '../assets/ocesha_images/ocesha_logo.png';
import Ocesha_header from "./ocesha_landing/ocesha_header";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// Define interface for referral event
interface ReferralEvent {
  detail: {
    referralId: string;
    // Add other properties if needed
  };
}

const PricingPage = () => {

  // Check if the user is logged in
  const isAuthenticated = localStorage.getItem("logged_in");

  const [mode, setMode] = useState<PaletteMode>("dark");
  const userRole = localStorage.getItem('userRole');
  //console.log('role of the user3=>' + userRole);
  // State to hold the text value
  const [couponCode, setCouponCode] = useState<string>('');



  // Function to handle text input change
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  };



  const colorMode1 = useMemo(
    () => ({

      toggleColorMode: () => {
        {
          userRole === 'admin' ?
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "dark" : "dark"
            )
        }
      },
    }),
    []
  );



  const theme1 = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const [getProduct, getState] = useLazyGetProductQuery();
  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
  const [period, getPeriodState] = useState("monthly");
  const [productModule, setProductModule] = useState("");
  const [additionalContract, setAdditionalContract] = useState(0);
  const [addon, setAddOn] = useState({
    plan_name: "",
    price: 0,
    price_id: "",
    product_id: "",
  });
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
      save: ""
    },
  ]);
  const [referralInfo, setReferralInfo] = useState<ReferralEvent | null>(null);
  const [showRemaining, setShowRemaining] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const user = useAppSelector((state) => state.userState.user);
  const genieSelector = useAppSelector((state) => state.genieState);
  //const getAddOn = useGetAddOnQuery({});

  const [planDetails, setPlanDetails] = useState<any>();
  const [getuserPlanDetails] = usePlanDetailsMutation();

  const location = useLocation();
  const { campIdFromUpgrade } = location.state || {};
  const { CreatingfromLanding } = location.state || {};
  const { UrlfromLanding } = location.state || {};
  const { uploadedvideo } = location.state || {};
  const { uploadedVideoName } = location.state || {};
  const { useremail } = location.state || {};
  const { fromNonSubscribed } = location.state || {};
  const { from_page } = location.state || {};
  const [helpervideosrc, setHelperVideoSrc] = useState<any>();
  const [helperPopup, setHelperPopup] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState(localStorage.getItem('activeItem') || 'dashboard');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const email = useremail ? useremail : localStorage.getItem("user_email");

  const fetchPlanDatas = async () => {
    try {
      const response = await getuserPlanDetails({ email: email || '', campaignid: '' });

      if ('data' in response) {
        setPlanDetails(response.data.data);
        console.log('plan details=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPlanDatas();
  }, []);

  useEffect(() => {
    // Function to handle referral information
    const handleReferral = (event: CustomEvent<ReferralEvent>) => {
      const referralData = event.detail;
      setReferralInfo(referralData);
    };

    // Add event listener for 'rewardful.referral'
    window.addEventListener('rewardful.referral', handleReferral as EventListener);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('rewardful.referral', handleReferral as EventListener);
    };
  }, []);


  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/api/products/get-add-on`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data);
        setAddOn(data.data);
      });
  }, [setAddOn]);

  useEffect(() => {

    const value = {
      _id: "",
      product_name: "CodeGenie",
      //product_module: genieSelector.module ? genieSelector.module : ""
      product_module: `${process.env.REACT_APP_MAIN_PRODUCT_NAME}`,
      userid: "",
      productid: ""
    };
    getProduct(value);
  }, [genieSelector, getProduct]);

  useEffect(() => {

    const { data } = getState;
    if (data?.plan_details) {
      console.log(data?.plan_details);
      setPlans([...data.plan_details]);
      setProductModule(data.product_module);
    }
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
          save: ''
        },
      ]);
  }, [getState]);

  useEffect(() => {
    if (checkoutState.isSuccess) {
      window.location = checkoutState.data?.checkout_url;
      if (CreatingfromLanding === 'true') {
        localStorage.setItem('CreatingfromLanding', CreatingfromLanding);
        if (UrlfromLanding) {
          localStorage.setItem('UrlfromLanding', UrlfromLanding);
        } else if (uploadedvideo && uploadedVideoName) {
          localStorage.setItem('uploadedvideo', uploadedvideo);
          localStorage.setItem('uploadedVideoName', uploadedVideoName);
        }
      }
      //window.open(checkoutState.data?.checkout_url, '_blank');
    }
  }, [checkoutState, navigate]);


  function handleSubmit(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    localStorage.setItem('user_email', email);
    e.preventDefault();

    if (subscription_level === "Free") {
      if (user?.email) {
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/signup");
      }
    } else {
      if (isAuthenticated != "true") {
        localStorage.setItem("fromPricing", "true");
        localStorage.setItem("subscription_level", subscription_level);
        let referral_param = ""
        if (referralInfo)
          referral_param = referralInfo.detail.referralId
        localStorage.setItem("referral_param", referral_param);
        localStorage.setItem("couponcode", couponCode);

        navigate('/signup');
      } else if (isAuthenticated == "true") {
        let email = useremail ? useremail : localStorage.getItem("user_email");

        let referral_param = ""
        if (referralInfo)
          referral_param = referralInfo.detail.referralId
        const fromUpgrade = localStorage.getItem('fromUpgrade');
        const username = localStorage.getItem('user_name');
        createCheckoutSession({ "emailid": email || "", "username": username/* user?.name */ || "", "subscription_level": subscription_level, "referral_param": referral_param || "", "couponcode": couponCode, "mode": fromUpgrade === 'true' && fromNonSubscribed !== 'true' ? "upgrade" : '', campaignid: campIdFromUpgrade ? campIdFromUpgrade : '', "from_page": from_page || 'pricing' });
      }

    }

  }

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    setAdditionalContract(Number(event.target.value));
  }

  function handlePayNow(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    if (isAuthenticated != "true") {
      navigate('/signin');
    } else {
      let referral_param = ""
      if (referralInfo)
        referral_param = referralInfo.detail.referralId
      const fromUpgrade = localStorage.getItem('fromUpgrade');
      createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level, "referral_param": referral_param || "", "couponcode": couponCode, "mode": fromUpgrade === 'true' ? "upgrade" : '', campaignid: '', "from_page": 'pricing' });
    }
  }

  // const NormallyValArray = [period == "monthly" ? '' : 'Normally $14/mo.', period == "monthly" ? '' : 'Normally $49/mo.', period == "monthly" ? '' : 'Normally $99/mo.', period == "monthly" ? '' : 'Normally $149/mo.'];

  const handlePlanToggle = () => {
    setShowRemaining(!showRemaining);
  }

  const fromUpgrade = localStorage.getItem('fromUpgrade');

  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }
  };

  const handleItemClick = (itemName: any) => {
    localStorage.setItem('activeItem', itemName);
    setActiveItem(localStorage.getItem('activeItem') ? localStorage.getItem('activeItem') : itemName);
    navigate('/codegenie/generated-blogs');
    if (itemName === 'upgrade') {
      const value = {
        _id: "",
        product_name: "CodeGenie",
        product_module: genieSelector.module ? genieSelector.module : "",
        userid: "",
        productid: ""
      };
      getProduct(value);
      localStorage.setItem('fromUpgrade', 'true');
      navigate('/pricing', { state: { campIdFromUpgrade: localStorage.getItem('campId') || ''/* selectedCampaign */, from_page: 'blog_listing' } });
    } else if (itemName === 'logout') {
      clearCookies();
      localStorage.setItem("logged_in", "false");
      //console.log("loggedin:" + localStorage.getItem("logged_in"));
      dispatch(logout());
      var module = localStorage.getItem("module");
      console.log("module111...." + module);

      toast.success("Logout success");
      // localStorage.removeItem("payment");
      localStorage.removeItem("msg");
      localStorage.removeItem("complexity");
      localStorage.removeItem("module");
      localStorage.removeItem("subscription_level");
      localStorage.removeItem("free_plan");
      // localStorage.removeItem("userRole");
      localStorage.removeItem("localStorage_msg");
      localStorage.removeItem("allSet");
      localStorage.removeItem("Blogs Generated");
      localStorage.removeItem("remaining_wish_count");
      localStorage.removeItem("userRole");
      localStorage.removeItem("payment");
      localStorage.removeItem("user_email");
      localStorage.removeItem("logged_in");
      localStorage.removeItem("prevImg");
      localStorage.removeItem("fromUpgrade");
      localStorage.removeItem("blogsCount");
      localStorage.removeItem("Generating Count");
      localStorage.removeItem("finalData");
      localStorage.removeItem("user_pass");
      localStorage.removeItem("fromNewcampaign");
      localStorage.removeItem("campaignid");
      localStorage.removeItem("returningtocampaign");
      localStorage.removeItem('sourceurl');
      localStorage.removeItem('campaignName');
      localStorage.removeItem('paymentOption');
      localStorage.removeItem('autoPublish');
      localStorage.removeItem('limitPublish');
      localStorage.removeItem('campId');
      localStorage.removeItem('campaignid');
      localStorage.removeItem('returningtocampaign');
      localStorage.removeItem('sourceurl');
      localStorage.removeItem('campaignName');
      localStorage.removeItem('paymentOption');
      localStorage.removeItem('autoPublish');
      localStorage.removeItem('limitPublish');
      localStorage.removeItem('smUrlpublish');
      localStorage.removeItem('addTags');
      localStorage.removeItem('captureLeads');
      localStorage.removeItem('newsLetter');
      localStorage.removeItem('twitterBtn');
      localStorage.removeItem('fbBtn');
      localStorage.removeItem('pintrestBtn');
      localStorage.removeItem('linkedinBtn');
      localStorage.removeItem('video');
      localStorage.removeItem('twitterurl');
      localStorage.removeItem('fburl');
      localStorage.removeItem('pintresturl');
      localStorage.removeItem('linkedinurl');
      localStorage.removeItem('owndomain');
      localStorage.removeItem('ChangepayMode');
      localStorage.removeItem('IdChangingMode');
      localStorage.removeItem('user_name');
      localStorage.removeItem('refreshCount');
      localStorage.removeItem('publishingCampId');
      localStorage.removeItem('Authorurl');
      localStorage.removeItem('Authorname');
      localStorage.removeItem('authorDefaultCheck');
      localStorage.removeItem('appsumo_coupon_code');
      localStorage.removeItem('podcastUrl');
      localStorage.removeItem('AlertMessage');
      localStorage.removeItem('CreatingfromLanding');
      localStorage.removeItem('UrlfromLanding');
      localStorage.removeItem('signedup');
      localStorage.removeItem('uploadedvideo');
      localStorage.removeItem('uploadedVideoName');
      localStorage.removeItem('uploadStatus');
      localStorage.removeItem('editedposthashtags');
      localStorage.removeItem('editedpostblogsummary');
      localStorage.removeItem('editedpostblogtitle');
      localStorage.removeItem('editedposturl');
      localStorage.removeItem('user_email');
      localStorage.removeItem('YoutubeUrlfromLanding');
      localStorage.removeItem('FirstLogin');
      localStorage.removeItem('DirectSignup');
      localStorage.removeItem('FromIndexPage');
      localStorage.removeItem('activeItem');
      localStorage.removeItem('UpdateduserImage');
      localStorage.removeItem('ErrorAlert');
      removeCookie('user_email');
      localStorage.clear();

      if (module == process.env.REACT_APP_MAIN_PRODUCT_NAME)
        navigate("/Anycode_landing");
      else
        navigate("/");
    }
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>

      {/* Header */}
      {localStorage.getItem('logged_in') === 'true' ? '' :
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-md">
              <div className="container-fluid">
                <a className="navbar-brand" href="/" style={{width: '5%'}}>
                  {/* <img src="./img/logo.png" alt="" /> */}
                  <img src={OceshaLogo} className="img-fluid" alt="" style={{ width: '60%' }} />

                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarScroll"
                  aria-controls="navbarScroll"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="">
                    <i className="bi bi-list text-white" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                  <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                    <li className="nav-item">
                      <a
                        className="nav-link nav_active"
                        style={{ color: "#4ba5eb", fontWeight: 800 }}
                        href="/"
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => {
                      localStorage.setItem('pricing_from_landing', 'true');
                      navigate("/pricing");
                    }} style={{ cursor: 'pointer' }}>
                      <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                        Pricing
                      </a>
                    </li>
                    {/*  <li className="nav-item" style={{ cursor: 'pointer' }}>
                      <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                        Affiliates
                      </a>
                    </li> */}
                    <li className="nav-item" onClick={() => {
                      navigate("/contactus");
                    }} style={{ cursor: 'pointer' }}>
                      <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                        Contact
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex gap-4" role="search">
                    <button className="btn btn-outline-primary px-3"
                      onClick={() => {
                        navigate("/signin");
                      }}>Sign  In</button>
                    <button className="button_leniar px-3" type="submit" onClick={() => {
                      navigate("/signup");
                    }}>
                      {/* Try Now */}Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </nav>
            <hr className="mt-3" style={{ color: "#9aa0a3" }} />
          </div>
        </div>
      }
      {/*  */}

      <div className="row">
        {localStorage.getItem('logged_in') === 'true' ? (isMobile ? (
          <div>
            {/* Hamburger Icon */}
            <button
              onClick={handleMenuToggle}
              style={{
                background: 'none',
                border: 'none',
                color: '#fff',
                fontSize: '24px',
                cursor: 'pointer',
                position: 'absolute',
                top: '-2px',
                left: '10px',
              }}
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </button>

            {/* Mobile Menu */}
            {isMenuOpen && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '50%',
                  height: '100%',
                  background: '#1F1F27',
                  zIndex: 1000,
                  padding: '20px',
                  overflowY: 'auto',
                }}
              >
                <div className="menu">
                  <ul className="menu-list">
                    {isMenuOpen ?
                      <li onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ display: 'flex', justifyContent: 'flex-end' }}><CloseIcon /></li> : ''}
                    <li className={`list ${activeItem === 'dashboard' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('dashboard'); setIsMenuOpen(false); }}><DashboardIco />Dashboard</li>
                    <li className={`list ${activeItem === 'blogs' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('blogs'); setIsMenuOpen(false); }}><BlogsIco />Blogs</li>
                    {/* <li className={`list ${activeItem === 'video' ? 'active' : ''}`}
                              onClick={() => handleItemClick('video')}><VideoIco />Video</li> */}
                    {/*  <li className={`list ${activeItem === 'audio' ? 'active' : ''}`}
                              onClick={() => handleItemClick('audio')}><AudioIco />Audio</li> */}
                    <li className={`list ${activeItem === 'images' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('images'); setIsMenuOpen(false); }}><ImagesIco />Images</li>
                    <li className={`list ${activeItem === 'createblog' ? 'active' : ''}`}
                      onClick={() => handleItemClick('createblog')}><AddOutlinedIcon sx={{ width: '20px', height: '21px' }} />Create Blog</li>
                    <hr />
                    {/*  <li className={`list ${activeItem === 'team' ? 'active' : ''}`}
                              onClick={() => handleItemClick('team')}><TeamIco />Team</li>
                          <li className={`list ${activeItem === 'integration' ? 'active' : ''}`}
                              onClick={() => handleItemClick('integration')}><IntegrationIco />Integration</li>
                          <hr /> */}
                    <li className={`list ${activeItem === 'upgrade' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('upgrade'); setIsMenuOpen(false); }}><UpgradeIco />Upgrade</li>
                    <li className={`list ${activeItem === 'billing' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('billing'); setIsMenuOpen(false); }}><BillingIco />Billing</li>
                    <li className={`list ${activeItem === 'settings' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('settings'); setIsMenuOpen(false); }}><SettingsIco />Settings</li>
                    {/* <li className={`list ${activeItem === 'help' ? 'active' : ''}`}
                              onClick={() => handleItemClick('help')}><HelpIco />Help</li> */}
                    <hr />
                    <li className={`list ${activeItem === 'logout' ? 'active' : ''}`}
                      onClick={() => { handleItemClick('logout'); setIsMenuOpen(false); }}><LogoutIco />Logout</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        )
          :
          <div className="col-2" style={{ background: '#1F1F27' }}>
            <div className="container" style={{ marginTop: '1%' }}>
              <div className="row">
                <div className="col-12" style={{ marginTop: '6%', marginLeft: '20%', display: 'flex', alignItems: 'center', gap: '12px', width: '66%' }}>
                  <a href="/"><img src={ocesha_logo} style={{width: '75%'}}/></a>
                  <Tooltip title="Click here for help video">
                    <img src={youtubeIco1} style={{ cursor: 'pointer', height: '40px', marginTop: '-9px', marginLeft: '-9px' }}
                      onClick={() => {
                        setHelperVideoSrc("/images/Overview.mp4");
                        setHelperPopup(true);
                      }} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="menu" style={{ marginTop: '30%', marginLeft: '10%' }}>
              <ul className="menu-list">
                <li className={`list ${activeItem === 'dashboard' ? 'active' : ''}`}
                  onClick={() => handleItemClick('dashboard')}><DashboardIco />Dashboard</li>
                <li className={`list ${activeItem === 'blogs' ? 'active' : ''}`}
                  onClick={() => handleItemClick('blogs')}><BlogsIco />Blogs</li>
                {/* <li className={`list ${activeItem === 'video' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('video')}><VideoIco />Video</li> */}
                {/*  <li className={`list ${activeItem === 'audio' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('audio')}><AudioIco />Audio</li> */}
                <li className={`list ${activeItem === 'images' ? 'active' : ''}`}
                  onClick={() => handleItemClick('images')}><ImagesIco />Images</li>
                <li className={`list ${activeItem === 'createblog' ? 'active' : ''}`}
                  onClick={() => handleItemClick('createblog')}><AddOutlinedIcon sx={{ width: '20px', height: '21px' }} />Create Blog</li>
                <hr />
                {/* <li className={`list ${activeItem === 'team' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('team')}><TeamIco />Team</li> */}
                {/* <li className={`list ${activeItem === 'integration' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('integration')}><IntegrationIco />Integration</li> */}
                {/* <hr /> */}
                <li className={`list ${activeItem === 'upgrade' ? 'active' : ''}`}
                  onClick={() => handleItemClick('upgrade')}><UpgradeIco />Upgrade</li>
                <li className={`list ${activeItem === 'billing' ? 'active' : ''}`}
                  onClick={() => handleItemClick('billing')}><BillingIco />Billing</li>
                <li className={`list ${activeItem === 'settings' ? 'active' : ''}`}
                  onClick={() => handleItemClick('settings')}><SettingsIco />Settings</li>
                {/* <li className={`list ${activeItem === 'help' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('help')}><HelpIco />Help</li> */}
                <hr />
                <li className={`list ${activeItem === 'logout' ? 'active' : ''}`}
                  onClick={() => handleItemClick('logout')}><LogoutIco />Logout</li>
              </ul>
            </div>
          </div>) : ''}

        <div className={`${localStorage.getItem('logged_in') === 'true' ? 'col-sm-12 col-md-10 col-lg-10' : 'col-sm-12 col-md-12 col-lg-12'}`}>
          {localStorage.getItem('logged_in') === 'true' ? <Ocesha_header handleItemClick={handleItemClick} /> : ''}
          <div className={`priceContainer ${localStorage.getItem('logged_in') === 'true' ? 'overlay-container' : ''}`} style={{ marginTop: localStorage.getItem('logged_in') === 'true' ? '1%' : '', borderTop: localStorage.getItem('logged_in') === 'true' ? '1px solid grey' : '' }}>
            {fromUpgrade && (
              <div className="row">
                <div className="col-md-6" style={{ display: 'flex', alignItems: 'center', marginTop: isMobile ? '3%' : '' }}>
                  <Tooltip title="Click here for help video">
                    <img src={youtubeIco1} style={{ cursor: 'pointer', height: '40px',/* color: 'red', fontSize: '34px', marginLeft: '5%', background: '#fff', borderRadius: '49%', */ marginTop: isMobile ? '3%' : '' }}
                      onClick={() => {
                        setHelperVideoSrc("/images/Pricing_page.mp4");
                        setHelperPopup(true);
                      }} /* onClick={() => window.open("https://youtu.be/YhH8Env_Nms", '_blank')} */ />
                  </Tooltip>
                </div>
                <div className="col-md-6">
                  {/* {fromUpgrade ? */}
                  <LoadingButton
                    style={{
                      background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                      color: "white",
                      fontSize: 'large',
                      fontWeight: 'bold',
                      marginLeft: 'auto',
                      marginTop: '1%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '10px',
                    }}
                  >
                    <div>Current Plan: {planDetails?.plan_name/* subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y' ? 'FREE' : planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_4' ? 'BASIC' : planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_5' ? 'PRO' : planDetails?.subscription_level === 'level_3' || planDetails?.subscription_level === 'level_6' ? 'TRAFFIC HACKER' : '' */}</div>
                    <div style={{ textAlign: 'left', fontSize: '18px' }}>You can upgrade or downgrade to any plan.<br />New plan will start at your next billing period.</div>
                  </LoadingButton> {/*  : ''}  */}
                </div>
              </div>)}
            <div className="price_head">



              {/* {isMobile && fromUpgrade ? <Button variant="outlined" className="HeadingBlogList" style={{ color: '#fff', fontWeight: 'lighter', width: isMobile ? '100%' : '20%', marginTop: '3%' }}><a style={{ textDecoration: 'none' }} href='https://youtu.be/y29AzqqbWRU' target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginRight: '8px' }}
          >
            <polygon points="10 17 15 12 10 7 10 17"></polygon>
            <circle cx="12" cy="12" r="10"></circle>
          </svg><span style={{ color: '#fff' }}>Site Overview Video</span></a></Button> : ''} */}

              <div className="head_bottom" style={{ marginTop: fromUpgrade ? '0px' : '', width: '100%', display: fromUpgrade && !isMobile ? 'flex' : '', gap: fromUpgrade && !isMobile ? '13%' : '' }}>
                {/* {!isMobile && fromUpgrade ? <Button variant="outlined" className="HeadingBlogList" style={{ color: '#fff', fontWeight: 'lighter', width: isMobile ? '100%' : '20%',  }}><a style={{ textDecoration: 'none' }} href='https://youtu.be/y29AzqqbWRU' target="_blank"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginRight: '8px' }}
            >
              <polygon points="10 17 15 12 10 7 10 17"></polygon>
              <circle cx="12" cy="12" r="10"></circle>
            </svg><span style={{ color: '#fff' }}>Site Overview Video</span></a></Button> : ''} */}
                {fromUpgrade ? <div style={{ textAlign: 'center', marginTop: isMobile ? '3%' : '' }}>
                  {/* <Tooltip title="Click here for help video">
              <InfoIcon sx={{ color: '#4BA5EB', fontSize: '34px' }} onClick={()=>window.open("https://youtu.be/YhH8Env_Nms", '_blank')}/>
              </Tooltip> */}
                  <h1>Upgrade/Downgrade Plan</h1>
                </div> :
                  <>
                    <h1>Choose Your Plan And Start</h1>
                    {/* <h1>Your Free 7-Day Trial</h1> */}
                  </>}
              </div>

              <div className="SaveUp" style={{ textAlign: isMobile ? 'center' : undefined }}>
                {/* {period == "monthly" ? */} Get 2 months for free by subscribing yearly{/* "Save $2,998/year with the ‘Traffic Hacker’ plan, billed annually." */} {/* : "Save $5,468/year with the 'Traffic Hacker' plan"} */}
              </div>
            </div>


            <div className="billingBtn">
              <Box component="div" textAlign="center" sx={{ p: 2 }}>

                <Button
                  size="large"
                  //variant={period==="monthly"?"contained":"text"}
                  sx={{
                    // background: `${period === "monthly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                    color: `${period === "monthly" ? "1px solid #4BA5EB" : "inherit"}`,
                    paddingX: 2,
                    paddingY: 1,
                    // borderBottom: `${period === "monthly" ? "3px solid #fff" : "none"}`,
                    borderRadius: '8px 0 0 8px',
                    fontSize: 'large',
                    border: `${period === "monthly" ? "1px solid #4BA5EB" : "1px solid #CBCBCB"}`,
                    height: '70px'
                  }}
                  onClick={() => {
                    getPeriodState("monthly");
                  }}
                >
                  {/*  Monthly billing */}
                  Pay Monthly<br />
                </Button>
                <Button
                  size="large"
                  //variant={period==="yearly"?"contained":"text"}
                  sx={{
                    // background: `${period === "yearly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                    color: `${period === "yearly" ? "1px solid #4BA5EB" : "inherit"}`,
                    paddingX: 2,
                    paddingY: 1,
                    // borderBottom: `${period === "yearly" ? "3px solid #fff" : "none"}`,
                    borderRadius: '0 8px 8px 0',
                    fontSize: 'large',
                    border: `${period === "yearly" ? "1px solid #4BA5EB" : "1px solid #CBCBCB"}`,
                    height: '70px'
                  }}
                  onClick={() => {
                    getPeriodState("yearly")
                  }}

                >
                  {/*  Yearly Discount */}Pay Yearly
                </Button>


              </Box>
            </div>

            {/* {period == 'monthly' ? */} <>{/* &nbsp;&nbsp;&nbsp;&nbsp; */}<span style={{ color: '#fff' }}>Redeem Coupon: <input className="px-3" style={{ marginBottom: '1%' }} type="text" value={couponCode} onChange={handleTextChange} name="couponcode" id="couponcode" ></input></span></> {/* : ''} */}

            {/* {!isMobile ? plans.filter((item) => item.period === period).length >= 2 &&
          <img src={rating_img} alt="" className="rightImage" /> : ''
        } */}
            <div className="price_table" style={{ position: 'relative' }}>
              {!isMobile && plans.filter((item) => item.period === period).length >= 2 && (
                <img
                  src={rating_img}
                  alt="Rating"
                  className="rightImage"
                  style={{
                    position: 'absolute',
                    top: '-190px',  // Adjust this value to move the image above the table
                    left: '54%'/* '29%' */,   // Center the image horizontally
                    transform: 'translateX(-12%)',
                    zIndex: 10,    // Ensure the image stays above other content
                  }}
                />
              )}
              {
                plans.filter((item) => item.period === period).map((plan, index) => (
                  <div className="columns" key={`plan_item_${index}`}>
                    {isMobile ? index === 3 && plans.filter((item) => item.period === period).length >= 3 && (
                      <img src={rating_img} alt="" className="rightImage" />
                    ) : ''}
                    {plan.plan_name === "Developers" && <div className="ribbon ribbon-top-right"><span>Popular</span></div>}

                    <ul className="price" style={{ boxShadow: plan.plan_name === 'TRAFFIC HACKER' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '', border: '2px solid ' + (plan.plan_name === 'TRAFFIC HACKER' ? '#B2B4B9' : '#202634'), height: '100%', background: index <= 2 ? '#0B0C14' : '#181920', display: 'flex', flexDirection: 'column' }}>
                      <div style={{ height: '248px', background: index === 3 ? '#272C3F' : '', borderBottom: '2px solid #202634', borderTopLeftRadius: '22px', borderTopRightRadius: '22px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {/* {index === 2 && plans.filter((item) => item.period === period).length >= 2 &&
                      <img src={rating_img} alt="" className="rightImage" /> 
                    } */}
                        <li className="tableHeader">{plan.plan_name /* === 'FREE' ? 'Free' : plan.plan_name === 'Starter' ? 'Starter' : plan.plan_name === 'Pro' ? 'Pro' : plan.plan_name === 'Pro+' ? 'Pro+' : '' */}</li>
                        {/* <li style={{ textAlign: 'center', textDecorationLine: 'line-through' }}>{plan.save ? `Normally $${plan.save}/mo.` : ''}* {NormallyValArray[index]} *</li> */}
                        <li className="planPrice" /* style={{ backgroundColor: period !== "monthly" ? "#E97131" : "transparent" }} */><b>$ {/* index === 2 && */ period == "monthly" ? Number(plan.price).toLocaleString() + `/Month` : Number(plan.price).toLocaleString() + `/Year`}</b></li>
                        <li className="planPrice">{(plan.period === "yearly" && plan.price == "48.8") && "Billed annually (Save $2.4/year)"}{(plan.period === "yearly" && plan.price == "82.5") && "Billed annually (Save $198/year)"}{(plan.period === "yearly" && plan.price == "165.8") && "Billed annually (Save $398/year)"}{(plan.period === "yearly" && plan.price == "249.16") && "Billed annually (Save $2,998/year)"}</li>
                        {/* {plan.user && <li className="tickli">User: <b>{plan.user}</b></li>} */}
                        {/* <li className="tickli">{productModule === "SMART CONTRACTS" ? "Audits" : "Wishes"}: <b>{plan.total_wishes}</b></li> */}

                        <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)}>
                          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em', padding: '2%' }}>
                            <Button
                              className="button"
                              size="large"
                              type="submit"
                              sx={{
                                // marginLeft: "20px",
                                background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                color: "white",
                                paddingX: 2,
                                paddingY: 1,
                                '&:hover': {
                                  background: "linear-gradient(270deg, #0168B5 0%, #4BA5EB 100%)",
                                },
                                width: '70%'
                              }}
                              endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                            >
                              {plan.plan_name === "Free" && "Sign in"}
                              {(plan.plan_name === "Individual" || plan.plan_name === "Startups") && "Pay Once"}
                              {fromUpgrade ? 'Upgrade' : (plan.plan_name !== "Individual" && plan.plan_name !== "Startups" && plan.plan_name !== "Free") && plan.button_text}
                            </Button>
                          </div>
                        </form>
                      </div>
                      <br />
                      {/* <hr></hr> */}

                      {Object.entries(plan.feature as unknown as Record<string, string[]>).map(([arrayName, arrayValues]) => (
                        <div key={arrayName}>
                          <ul>
                            <li
                              /*  className="arrayName" */
                              style={{ fontWeight: 'bold', color: '#4BA5EB', listStyleType: 'none', marginLeft: '7%' }}
                              dangerouslySetInnerHTML={{ __html: `${arrayName}:` }}
                            />
                            {arrayValues.map((feature: any, index: number) => (
                              <li
                                key={index}
                                className={feature === '' ? 'notickli' : 'tickli'}
                                dangerouslySetInnerHTML={{
                                  __html: feature === '-' ? '&nbsp;' : feature,
                                }}
                              />
                            ))}
                          </ul>
                        </div>
                      ))}

                      <div style={{ marginTop: 'auto' }} />
                      <li className="sub_button">
                        <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)}>
                          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em', marginTop: "10px" }}>
                            <Button
                              className="button"
                              size="large"
                              type="submit"
                              sx={{
                                width: "70%",
                                // marginLeft: "25px",
                                background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                color: "white",
                                paddingX: 2,
                                paddingY: 1,
                              }}
                              style={{ color: 'white' }}
                              endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                            >
                              {plan.plan_name === "Free" && "Sign in"}
                              {(plan.plan_name === "Individual" || plan.plan_name === "Startups") && "Pay Once"}
                              {fromUpgrade ? 'Upgrade' : (plan.plan_name !== "Individual" && plan.plan_name !== "Startups" && plan.plan_name !== "Free") && plan.button_text}
                            </Button>
                          </div>
                        </form>
                      </li>
                    </ul>
                  </div>

                ))
              }
            </div>
          </div>
        </div>
      </div>

      {/* Footer section */}
      <div className="py-5 mx-sm-0 mx-2">
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}><img src={img54} alt="" style={{ width: '10%' }} />&nbsp;Ocesha</Typography>

                </div>
                <p /* className="fw_samibold" */ style={{ fontSize: 14, fontWeight: 'lighter' }}>
                  {/* Mr John Smith. 132, My Street, Kingston, New York 12401. */}
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-sm-4">
              &nbsp;
            </div>
            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
              &nbsp;
            </div>
            <div className="col-lg-5 col-sm-4  text-lg-start text-sm-end" style={{ display: 'flex', justifyContent: 'right' }}>
              Copyright © 2024 Ocesha.com | All Rights Reserved.
            </div>

          </div>
        </div>
      </div>
      {/* footer section end*/}


      <p></p>
      <p></p>

      <Modal
        open={helperPopup}
        /* onClose={(event, reason) => {
            if (reason !== "backdropClick") {
                setHelperModal(false);
            }
        }} */
        disableEscapeKeyDown
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "70%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: '15px',
            padding: '3%'
          }}
        >
          {/* <video
                        controls
                        style={{ width: '100%' }}
                        src={helpervideosrc}
                        autoPlay
                    /> */}
          <iframe
            width="100%"
            height="500"
            src={helpervideosrc}
            title="Help Video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
          <Stack flexDirection="row">
            <Button
              onClick={() => setHelperPopup(false)}
              variant="contained"
              sx={{ mr: 2, marginTop: '15%' }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default PricingPage;