import React, { useState, useEffect, useRef } from "react";
import sample_setting_img from '../../assets/img/card-img-3.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './ocesha.css';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useGetUserDetailsMutation } from "redux/api/authApi";
import { usePlanDetailsMutation, useLazyGetProductQuery } from "redux/api/productApi";
import { Modal, Button, Stack, Box, Typography, Menu, MenuItem, Tooltip, CircularProgress } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector } from "redux/store";
import { IPlanDetail } from "redux/api/types";
import { ReactComponent as LogoutIco } from '../../assets/ocesha_images/logout_ico.svg';
import { ReactComponent as SettingsIco } from '../../assets/ocesha_images/settings_ico.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import youtubeIco from '../../assets/traffic_monster/youtubeIco_1.png';
import { toast } from "react-toastify";

interface OceshaHeaderProps {
    handleItemClick: (itemName: any) => void;
}

const Ocesha_header: React.FC<OceshaHeaderProps> = ({ handleItemClick }) => {

    const navigate = useNavigate();
    const currentUrl = window.location.pathname;

    const [userDetails, setUserDetails] = useState<any>();
    const [planDetails, setPlanDetails] = useState<any>();
    const [subscription_level, setSubscriptionlevel] = useState<any>();
    const [upgradeModal, setUpgradeModal] = useState<boolean>(false);
    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
            save: ''
        },
    ]);
    const [hoverSettings, setHoverSettings] = useState(false);
    const [hoverLogout, setHoverLogout] = useState(false);

    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [getProduct, getProductState] = useLazyGetProductQuery();
    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState<any>();
    const [viewDropdown, setViewDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [helpervideosrc, setHelperVideoSrc] = useState<any>();
    const [helperPopup, setHelperPopup] = useState<boolean>(false);
    const [openActivateConfirmationModal, setOpenActivateConfirmationModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const userEmail = localStorage.getItem('user_email') || '';
    const isSubmitting = useRef(false);

    useEffect(() => {

        const { data } = getProductState;
        if (data?.plan_details) {
            //console.log(data?.plan_details);
            setPlans([...data?.plan_details]);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                    save: ''
                },
            ]);
    }, [getProductState]);

    useEffect(() => {
        localStorage.setItem('user_email', userEmail);
    }, [userEmail]);
    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: userEmail });

            if ('data' in response) {
                setUserDetails(response.data.data);
                console.log('userDet=>', response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const campId = localStorage.getItem('campId') || '';

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: userEmail, campaignid: ''/* campId */ });

            if ('data' in response) {
                setPlanDetails(response.data.data);
                console.log('plan details=>', response.data.data);
                setSubscriptionlevel(planDetails.subscription_level);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (campId) {
            fetchPlanDatas();
        }
    }, [campId]);

    const url = window.location.pathname + window.location.search;

    const handleViewDropdownClick = () => {
        setViewDropdown((prevState) => !prevState);
    }
    /*  useEffect(() => {
         const handleClickOutside = (event: MouseEvent) => {
             if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                 setViewDropdown(false); // Close the dropdown if the click is outside
             }
         };
 
         // Attach the event listener
         document.addEventListener('mousedown', handleClickOutside);
 
         // Cleanup the event listener on unmount
         return () => {
             document.removeEventListener('mousedown', handleClickOutside);
         };
     }, []); */
    const userImage = localStorage.getItem('UpdateduserImage') !== 'undefined' ? localStorage.getItem('UpdateduserImage') : userDetails?.user_image;

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActivateSubscription = async () => {
        if (isSubmitting.current) return;
        isSubmitting.current = true;

        setIsLoading(true);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';

            const response = await fetch(apiUrl + `/api/subscription/activate_now/?email=${userEmail}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userEmail }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Subscription activated:', data);
            toast.success(data.message);
            navigate('/activation-success');
        } catch (error) {
            toast.error('Error activating subscription');
            console.error('Error activating subscription:', error);
        } finally {
            isSubmitting.current = false;
            setIsLoading(false);
            // setOpenActivationModal(true);
        }
    }

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <>
            <div className="container" style={{ marginTop: '1%', background: '#1F1F27' }}>
                <div className="row">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        {currentUrl === '/codegenie/generated-blogs' && localStorage.getItem('activeItem') === 'blogs' ?
                            <Tooltip title="Click here for help video">
                                <img src={youtubeIco} style={{ cursor: 'pointer', height: '40px' }}
                                    onClick={() => {
                                        setHelperVideoSrc("/images/My_Blogs_Page.mp4");
                                        setHelperPopup(true);
                                    }} />
                            </Tooltip> : ''}
                        {userDetails?.payment_status === 'Free Trial' ?
                            <Button
                                sx={{
                                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                                    marginLeft: 0,
                                    color: "white",
                                    paddingX: 2,
                                    paddingY: 1,
                                    marginRight: 1,
                                    fontWeight: 'bold',
                                    borderRadius: '35px',
                                    width: '14%'
                                }}
                                onClick={() => setOpenActivateConfirmationModal(true)}
                            >
                                Activate Plan
                            </Button> : ''}
                        {isMobile ? '' :
                            (url !== '/codegenie/new-campaign' && url !== '/codegenie/dashboard') ?
                                <button className="button_leniar" style={{ fontWeight: 'lighter', borderRadius: '35px', width: '14%' }}
                                    onClick={() => {
                                        const clearLocalStorage = () => {
                                            const keysToRemove = ["campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption",
                                                "autoPublish", "limitPublish", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn",
                                                "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "useOwnDomain", "ChangepayMode", "IdChangingMode", "Authorurl", "Authorname",
                                                "authorDefaultCheck", "videoTag", "podcastUrl", "sourcetext"];
                                            keysToRemove.forEach(key => localStorage.removeItem(key));
                                        };
                                        if (userDetails?.mode === 'Canceled') {
                                            setUpgradeModal(true);
                                            return;
                                        }
                                        if (planDetails?.subscription_level !== undefined || planDetails !== '' || userDetails.blog_count_balance > 0 || userDetails.podcast_count_balance > 0) {
                                            localStorage.setItem('activeItem', 'createblog');
                                            navigate('/codegenie/new-campaign');
                                            clearLocalStorage();
                                        } else if (userDetails?.blog_count_balance === 0 && userDetails?.podcast_count_balance === 0) {
                                            setUpgradeModal(true);
                                        } else {
                                            localStorage.setItem('fromUpgrade', 'true');
                                            navigate('/pricing', { state: { 'campaignIdFromBL': localStorage.getItem('campId'), 'from_page': 'blog_listing', 'useremail': userEmail/* userDets?.email */ } });
                                        }
                                    }}>Create Blog</button> : ''}
                        {userImage ? <img src={userImage} style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }} alt="" /> : userDetails?.user_image ? <img src={userDetails?.user_image} style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }} alt="" /> : <AccountCircleIcon style={{ alignSelf: 'center' }} />}
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-1%' }}>
                            <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={handleClick}/* onClick={() => handleViewDropdownClick()} */ />
                            {/* {viewDropdown && (
                                <div
                                    ref={dropdownRef}
                                    style={{
                                        position: 'absolute',
                                        right: isMobile ? '6%' : '20px',
                                        backgroundColor: '#36393F',
                                        border: '1px solid #CACBCC',
                                        padding: '10px',
                                        zIndex: 1000,
                                        marginTop: isMobile ? '152px' : '160px',
                                        borderRadius: '5px',
                                        width: isMobile ? '30%' : '12%'
                                    }}>
                                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                        <li style={{ padding: '8px 16px', cursor: 'pointer', color: hoverSettings ? ' #FFFFFF' : '#CACBCC', background: hoverSettings ? '#2A2E35' : 'transparent', borderRadius: hoverSettings ? '5px' : '0px' }} onMouseEnter={() => setHoverSettings(true)}
                                            onMouseLeave={() => setHoverSettings(false)} onClick={() => handleItemClick('settings')}>
                                            <SettingsIco />&ensp;Settings
                                        </li>
                                        <li style={{ padding: '8px 16px', cursor: 'pointer', color: hoverLogout ? ' #FFFFFF' : '#CACBCC', background: hoverLogout ? '#2A2E35' : 'transparent', borderRadius: hoverLogout ? '5px' : '0px' }} onMouseEnter={() => setHoverLogout(true)}
                                            onMouseLeave={() => setHoverLogout(false)} onClick={() => handleItemClick('logout')}>
                                            <LogoutIco />&ensp;Logout
                                        </li>
                                    </ul>
                                </div>
                            )} */}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        backgroundColor: '#36393F',
                                        color: '#CACBCC',
                                        borderRadius: '5px',
                                        minWidth: '214px',
                                        boxShadow: 'none',
                                        border: '1px solid #CACBCC',
                                        top: '65px'
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem style={{ color: '#CACBCC' }} onClick={() => { handleClose(); handleItemClick('settings'); }}>
                                    <SettingsIco fontSize="small" style={{ marginRight: '8px' }} />
                                    Settings
                                </MenuItem>
                                <MenuItem style={{ color: '#CACBCC' }} onClick={() => { handleClose(); handleItemClick('logout'); }}>
                                    <LogoutIco fontSize="small" style={{ marginRight: '8px' }} />
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>

            {/* popups */}
            <Modal
                open={upgradeModal}
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "40%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}><InfoIcon sx={{ color: '#ffcc00' }} /><br />{userDetails?.mode === 'Canceled' ? 'Please upgrade your plan to continue.' : 'Blog count exceeds your plan limit. Please upgrade your plan to continue.'}</Typography>

                    <Stack flexDirection="row" sx={{ display: 'flex', gap: '10px', marginTop: '1%' }}>

                        <Button
                            variant="contained"
                            onClick={() => {
                                const value = {
                                    _id: "",
                                    product_name: "CodeGenie",
                                    product_module: genieSelector.module ? genieSelector.module : "",
                                    userid: "",
                                    productid: ""
                                };
                                getProduct(value);
                                localStorage.setItem('fromUpgrade', 'true');
                                navigate('/pricing', { state: { campIdFromUpgrade: '', from_page: 'blog_listing' } });
                            }}>
                            Upgrade
                        </Button>

                        <Button
                            onClick={() => setUpgradeModal(false)}
                            variant="contained"
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={helperPopup}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    {/* <video
                        controls
                        style={{ width: '100%' }}
                        src={helpervideosrc}
                        autoPlay
                    /> */}
                    <iframe
                        width="100%"
                        height="500"
                        src={helpervideosrc}
                        title="Help Video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                    <Stack flexDirection="row">
                        <Button
                            onClick={() => setHelperPopup(false)}
                            variant="contained"
                            sx={{ mr: 2, marginTop: '15%' }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openActivateConfirmationModal}
                onClose={() => setOpenActivateConfirmationModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 600,
                        bgcolor: "background.paper",
                        // border: "2px solid #000",
                        borderRadius: '5px',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    {/* <TaskAltIcon style={{ color: 'green', fontSize: '40px' }} /> */}
                    <p id="child-modal-description" style={{ textAlign: 'center' }}>Activate Plan will immediately start your paid plan and bill your credit card.<br></br>CONTINUE ?</p>
                    <Stack flexDirection="row">
                        {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
                          <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
                        </Box> */}

                        <Button
                            onClick={handleActivateSubscription}
                            variant="contained"
                            sx={{ mr: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : "Yes"}
                        </Button>

                        <Button
                            onClick={() => {
                                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                                setOpenActivateConfirmationModal(false);
                            }}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            No
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            {/*  */}
        </>
    )
}

export default Ocesha_header;