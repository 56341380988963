import React, { useState, useEffect, useRef } from "react";
import Blogs_Section from "./blogs_section";
import { Button, MenuItem, Select, Modal, Box, Stack, Tooltip, Typography } from "@mui/material";
import sample_setting_img from '../../assets/img/card-img-3.png';
import { LoadingButton } from "@mui/lab";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Ocesha_header from "./ocesha_header";
import Ocesha_new_des_logo from "../../assets/ocesha_images/ocesha_new_des_logo.png";
import './ocesha.css';
import { ReactComponent as BlogsIco } from '../../assets/ocesha_images/blogs_ico.svg';
import { ReactComponent as VideoIco } from '../../assets/ocesha_images/video_ico.svg';
import { ReactComponent as AudioIco } from '../../assets/ocesha_images/audio_ico.svg';
import { ReactComponent as ImagesIco } from '../../assets/ocesha_images/images_ico.svg';
import { ReactComponent as TeamIco } from '../../assets/ocesha_images/team_ico.svg';
import { ReactComponent as IntegrationIco } from '../../assets/ocesha_images/integration_ico.svg';
import { ReactComponent as UpgradeIco } from '../../assets/ocesha_images/upgrade_ico.svg';
import { ReactComponent as BillingIco } from '../../assets/ocesha_images/billing_ico.svg';
import { ReactComponent as SettingsIco } from '../../assets/ocesha_images/settings_ico.svg';
import { ReactComponent as HelpIco } from '../../assets/ocesha_images/help_ico.svg';
import { ReactComponent as LogoutIco } from '../../assets/ocesha_images/logout_ico.svg';
import { ReactComponent as DashboardIco } from '../../assets/ocesha_images/dashboard_ico.svg';
import OceshaDashboard from "./ocesha_dashboard";
import Image_listing from "./image_listing";
import { isMobile } from "react-device-detect";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useGetUserDetailsMutation } from '../../redux/api/authApi';
import { usePublishUnpublishCountMutation, useCampaignListMutation } from '../../redux/api/promptApi';
import { usePlanDetailsMutation, useLazyGetProductQuery } from '../../redux/api/productApi';
import { useAppSelector } from "../../redux/store";
import { IPlanDetail } from '../../redux/api/types';
import { getCookie } from "utils/storageUtils";
import { useNavigate, useLocation } from "react-router-dom";
import Usersetting from "pages/Usersetting";
import { logout } from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/store";
import { removeCookie } from "utils/storageUtils";
import { toast } from "react-toastify";
import ocesha_logo from '../../assets/ocesha_images/ocesha_logo.png';
import Billing from "./billing";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import youtubeIco1 from '../../assets/traffic_monster/youtubeIco_1.png';
import InfoIcon from '@mui/icons-material/Info';

const Ocesha_new_design = () => {

    const [activeItem, setActiveItem] = useState(localStorage.getItem('activeItem') || 'dashboard');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { FromCampaign } = location.state || {};
    const { userEmail } = location.state || {};
    const { FromUpdatedCampaign } = location.state || {};

    /*  const chartRef = useRef<HTMLCanvasElement | null>(null);
     const chartInstance = useRef<Chart | null>(null); */

    const [userDets, setUserDets] = useState<any>();
    const [publishedUnpublishedCount, setPublishedUnpublishedCount] = useState<any>();
    const [planDetails, setPlanDetails] = useState<any>();
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignListDatas, setCampaignListDatas] = useState<any>();
    const [helpervideosrc, setHelperVideoSrc] = useState<any>();
    const [helperPopup, setHelperPopup] = useState<boolean>(false);

    const [getUserDetailsMutation] = useGetUserDetailsMutation();
    const [publishUnpublishCountMutation] = usePublishUnpublishCountMutation();
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [getProduct, getProductState] = useLazyGetProductQuery();
    const [campaignListMutation] = useCampaignListMutation();
    const [upgradeModal, setUpgradeModal] = useState<boolean>(false);

    const useremail = localStorage.getItem('user_email') || ''/* ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email') */;

    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: useremail || getCookie('user_email') || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchingPublishDatas = async (campaignId: string) => {
        try {
            const responseCount = await publishUnpublishCountMutation({ email: useremail || getCookie('user_email') || '', campaignid: campaignId });

            if ('data' in responseCount) {
                const datas = responseCount.data;
                setPublishedUnpublishedCount(datas);
            }
        } catch (error) {
            console.log('Error fetching publishing counts data:', error);
        }
    }

    useEffect(() => {
        fetchingPublishDatas('');
    }, []);

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: useremail, campaignid: '' });

            if ('data' in response) {
                setPlanDetails(response.data.data);
            }
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        fetchPlanDatas();
    }, []);

    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
            save: ''
        },
    ]);

    useEffect(() => {

        const { data } = getProductState;
        if (data?.plan_details) {
            //console.log(data?.plan_details);
            setPlans([...data?.plan_details]);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                    save: ''
                },
            ]);
    }, [getProductState]);

    const campaignRes = async () => {
        try {
            const response = await campaignListMutation({ email: useremail || '' });
            if ('data' in response) {
                setCampaignListDatas(response.data);
                console.log('campaign list response=>', response.data)
            } else {
                //console.error('Error fetching campaign List:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching campaign List:', error);
        }
    }

    useEffect(() => {
        const publishingblogCampId = localStorage.getItem('publishingCampId');
        if (publishingblogCampId) {
            setSelectedCampaign(publishingblogCampId);
            fetchingPublishDatas(publishingblogCampId);
        }
        else if (FromCampaign) {

            setSelectedCampaign(FromCampaign);
            fetchingPublishDatas(FromCampaign);
            localStorage.setItem('campId', FromCampaign);

            const count = 0;
            localStorage.setItem('refreshCount', count.toString());

            const refreshPage = () => {
                const refreshCountStr = localStorage.getItem('refreshCount');
                const refreshCountt = refreshCountStr !== null ? parseInt(refreshCountStr) : 0;

                if (refreshCountt < 1) {
                    setTimeout(() => {
                        localStorage.setItem('refreshCount', (refreshCountt + 1).toString());
                        // window.location.reload();
                        setSelectedCampaign(FromCampaign);
                        fetchingPublishDatas(FromCampaign);

                        // processBlogs();

                    }, 20000);
                }
            };
            refreshPage();

            // **Set FromCampaign to null**
            navigate(location.pathname, {
                replace: true,
                state: { ...location.state, FromCampaign: null },
            });

        } else if (FromUpdatedCampaign) {
            setSelectedCampaign(FromUpdatedCampaign);
            fetchingPublishDatas(FromUpdatedCampaign);
            localStorage.setItem('campId', FromUpdatedCampaign);
        } else if (campaignListDatas && campaignListDatas.length > 0 /* && !selectedCampaign */) {
            const savedCampId = localStorage.getItem('campId');
            if (!savedCampId) {
                // Only set to the 0th index if no other campaign was previously selected
                setSelectedCampaign(campaignListDatas[0].campaign_id);
                fetchingPublishDatas(campaignListDatas[0].campaign_id);
                localStorage.setItem('campId', campaignListDatas[0].campaign_id);
            } else {
                setSelectedCampaign(savedCampId);
                fetchingPublishDatas(savedCampId);
            }
            /* setSelectedCampaign(campaignListDatas[0].campaign_id);
            fetchingPublishDatas(campaignListDatas[0].campaign_id);
            localStorage.setItem('campId', campaignListDatas[0].campaign_id); */

        }
    }, [campaignListDatas, FromCampaign, FromUpdatedCampaign, location, navigate]);

    useEffect(() => {
        campaignRes();
    }, []);

    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [name] = cookie.split("=");
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
    };

    const handleItemClick = (itemName: any) => {
        localStorage.setItem('activeItem', itemName);
        setActiveItem(localStorage.getItem('activeItem') ? localStorage.getItem('activeItem') : itemName);

        if (itemName === 'upgrade') {
            const value = {
                _id: "",
                product_name: "CodeGenie",
                product_module: genieSelector.module ? genieSelector.module : "",
                userid: "",
                productid: ""
            };
            getProduct(value);
            localStorage.setItem('fromUpgrade', 'true');
            navigate('/pricing', { state: { campIdFromUpgrade: selectedCampaign, from_page: 'blog_listing' } });
        } else if (itemName === 'logout') {
            clearCookies();
            localStorage.setItem("logged_in", "false");
            //console.log("loggedin:" + localStorage.getItem("logged_in"));
            dispatch(logout());
            var module = localStorage.getItem("module");
            console.log("module111...." + module);

            toast.success("Logout success");
            // localStorage.removeItem("payment");
            localStorage.removeItem("msg");
            localStorage.removeItem("complexity");
            localStorage.removeItem("module");
            localStorage.removeItem("subscription_level");
            localStorage.removeItem("free_plan");
            // localStorage.removeItem("userRole");
            localStorage.removeItem("localStorage_msg");
            localStorage.removeItem("allSet");
            localStorage.removeItem("Blogs Generated");
            localStorage.removeItem("remaining_wish_count");
            localStorage.removeItem("userRole");
            localStorage.removeItem("payment");
            localStorage.removeItem("user_email");
            localStorage.removeItem("logged_in");
            localStorage.removeItem("prevImg");
            localStorage.removeItem("fromUpgrade");
            localStorage.removeItem("blogsCount");
            localStorage.removeItem("Generating Count");
            localStorage.removeItem("finalData");
            localStorage.removeItem("user_pass");
            localStorage.removeItem("fromNewcampaign");
            localStorage.removeItem("campaignid");
            localStorage.removeItem("returningtocampaign");
            localStorage.removeItem('sourceurl');
            localStorage.removeItem('campaignName');
            localStorage.removeItem('paymentOption');
            localStorage.removeItem('autoPublish');
            localStorage.removeItem('limitPublish');
            localStorage.removeItem('campId');
            localStorage.removeItem('campaignid');
            localStorage.removeItem('returningtocampaign');
            localStorage.removeItem('sourceurl');
            localStorage.removeItem('campaignName');
            localStorage.removeItem('paymentOption');
            localStorage.removeItem('autoPublish');
            localStorage.removeItem('limitPublish');
            localStorage.removeItem('smUrlpublish');
            localStorage.removeItem('addTags');
            localStorage.removeItem('captureLeads');
            localStorage.removeItem('newsLetter');
            localStorage.removeItem('twitterBtn');
            localStorage.removeItem('fbBtn');
            localStorage.removeItem('pintrestBtn');
            localStorage.removeItem('linkedinBtn');
            localStorage.removeItem('video');
            localStorage.removeItem('twitterurl');
            localStorage.removeItem('fburl');
            localStorage.removeItem('pintresturl');
            localStorage.removeItem('linkedinurl');
            localStorage.removeItem('owndomain');
            localStorage.removeItem('ChangepayMode');
            localStorage.removeItem('IdChangingMode');
            localStorage.removeItem('user_name');
            localStorage.removeItem('refreshCount');
            localStorage.removeItem('publishingCampId');
            localStorage.removeItem('Authorurl');
            localStorage.removeItem('Authorname');
            localStorage.removeItem('authorDefaultCheck');
            localStorage.removeItem('appsumo_coupon_code');
            localStorage.removeItem('podcastUrl');
            localStorage.removeItem('AlertMessage');
            localStorage.removeItem('CreatingfromLanding');
            localStorage.removeItem('UrlfromLanding');
            localStorage.removeItem('signedup');
            localStorage.removeItem('uploadedvideo');
            localStorage.removeItem('uploadedVideoName');
            localStorage.removeItem('uploadStatus');
            localStorage.removeItem('editedposthashtags');
            localStorage.removeItem('editedpostblogsummary');
            localStorage.removeItem('editedpostblogtitle');
            localStorage.removeItem('editedposturl');
            localStorage.removeItem('user_email');
            localStorage.removeItem('YoutubeUrlfromLanding');
            localStorage.removeItem('FirstLogin');
            localStorage.removeItem('DirectSignup');
            localStorage.removeItem('FromIndexPage');
            localStorage.removeItem('activeItem');
            localStorage.removeItem('UpdateduserImage');
            localStorage.removeItem('FromEditBlog');
            localStorage.removeItem('ErrorAlert');
            removeCookie('user_email');
            localStorage.clear();

            if (module == process.env.REACT_APP_MAIN_PRODUCT_NAME)
                navigate("/Anycode_landing");
            else
                navigate("/");
        }
    };

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (activeItem === 'createblog') {
            navigate('/codegenie/new-campaign');
        }
    }, [activeItem, navigate]);

    return (
        <>
            <div /* className="container" */ style={{
                overflowX: 'hidden',
                width: '100%',
                boxSizing: 'border-box',
            }}>
                {/* <!-- Sidebar --> */}
                <div className="row">
                    {isMobile ? (
                        <div>
                            {/* Hamburger Icon */}
                            <button
                                onClick={handleMenuToggle}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '-2px',
                                    left: '10px',
                                }}
                            >
                                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                            </button>

                            {/* Mobile Menu */}
                            {isMenuOpen && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '50%',
                                        height: '100%',
                                        background: '#1F1F27',
                                        zIndex: 1000,
                                        padding: '20px',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <div className="menu">
                                        <ul className="menu-list">
                                            {isMenuOpen ?
                                                <li onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ display: 'flex', justifyContent: 'flex-end' }}><CloseIcon /></li> : ''}
                                            <li className={`list ${activeItem === 'dashboard' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('dashboard'); setIsMenuOpen(false); }}><DashboardIco />Dashboard</li>
                                            <li className={`list ${activeItem === 'blogs' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('blogs'); setIsMenuOpen(false); }}><BlogsIco />Blogs</li>
                                            {/* <li className={`list ${activeItem === 'video' ? 'active' : ''}`}
                                                onClick={() => handleItemClick('video')}><VideoIco />Video</li> */}
                                            {/*  <li className={`list ${activeItem === 'audio' ? 'active' : ''}`}
                                                onClick={() => handleItemClick('audio')}><AudioIco />Audio</li> */}
                                            <li className={`list ${activeItem === 'images' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('images'); setIsMenuOpen(false); }}><ImagesIco />Images</li>
                                            <li className={`list ${activeItem === 'createblog' ? 'active' : ''}`}
                                                onClick={() => {
                                                    if (userDets?.mode === 'Canceled') {
                                                        setUpgradeModal(true);
                                                        return;
                                                    } else {
                                                        handleItemClick('createblog')
                                                    }
                                                }}><AddOutlinedIcon sx={{ width: '20px', height: '21px' }} />Create Blog</li>
                                            <hr />
                                            {/*  <li className={`list ${activeItem === 'team' ? 'active' : ''}`}
                                                onClick={() => handleItemClick('team')}><TeamIco />Team</li>
                                            <li className={`list ${activeItem === 'integration' ? 'active' : ''}`}
                                                onClick={() => handleItemClick('integration')}><IntegrationIco />Integration</li>
                                            <hr /> */}
                                            <li className={`list ${activeItem === 'upgrade' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('upgrade'); setIsMenuOpen(false); }}><UpgradeIco />Upgrade</li>
                                            <li className={`list ${activeItem === 'billing' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('billing'); setIsMenuOpen(false); }}><BillingIco />Billing</li>
                                            <li className={`list ${activeItem === 'settings' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('settings'); setIsMenuOpen(false); }}><SettingsIco />Settings</li>
                                            {/* <li className={`list ${activeItem === 'help' ? 'active' : ''}`}
                                                onClick={() => handleItemClick('help')}><HelpIco />Help</li> */}
                                            <hr />
                                            <li className={`list ${activeItem === 'logout' ? 'active' : ''}`}
                                                onClick={() => { handleItemClick('logout'); setIsMenuOpen(false); }}><LogoutIco />Logout</li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) :
                        (
                            <div className="col-2" style={{ background: '#1F1F27' }}>
                                <div className="container" style={{ marginTop: '1%' }}>
                                    <div className="row">
                                        <div className="col-12" style={{ marginTop: '6%', marginLeft: '20%', display: 'flex', alignItems: 'center', gap: '12px', width: '66%' }}>
                                            <a href="/"><img src={ocesha_logo} style={{width: '75%'}}/></a>
                                            <Tooltip title="Click here for help video">
                                                <img src={youtubeIco1} style={{ cursor: 'pointer', height: '40px', marginTop: '-9px', marginLeft: '-9px' }}
                                                    onClick={() => {
                                                        setHelperVideoSrc("/images/Overview.mp4");
                                                        setHelperPopup(true);
                                                    }} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu" style={{ marginTop: '30%', marginLeft: '10%' }}>
                                    <ul className="menu-list">
                                        <li className={`list ${activeItem === 'dashboard' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('dashboard')}><DashboardIco />Dashboard</li>
                                        <li className={`list ${activeItem === 'blogs' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('blogs')}><BlogsIco />Blogs</li>
                                        {/* <li className={`list ${activeItem === 'video' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('video')}><VideoIco />Video</li> */}
                                        {/*  <li className={`list ${activeItem === 'audio' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('audio')}><AudioIco />Audio</li> */}
                                        <li className={`list ${activeItem === 'images' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('images')}><ImagesIco />Images</li>
                                        <li className={`list ${activeItem === 'createblog' ? 'active' : ''}`}
                                            onClick={() => {
                                                if (userDets?.mode === 'Canceled') {
                                                    setUpgradeModal(true);
                                                    return;
                                                } else {
                                                    handleItemClick('createblog')
                                                }
                                            }}><AddOutlinedIcon sx={{ width: '20px', height: '21px' }} />Create Blog</li>
                                        <hr />
                                        {/* <li className={`list ${activeItem === 'team' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('team')}><TeamIco />Team</li> */}
                                        {/* <li className={`list ${activeItem === 'integration' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('integration')}><IntegrationIco />Integration</li> */}
                                        {/* <hr /> */}
                                        <li className={`list ${activeItem === 'upgrade' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('upgrade')}><UpgradeIco />Upgrade</li>
                                        <li className={`list ${activeItem === 'billing' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('billing')}><BillingIco />Billing</li>
                                        <li className={`list ${activeItem === 'settings' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('settings')}><SettingsIco />Settings</li>
                                        {/* <li className={`list ${activeItem === 'help' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('help')}><HelpIco />Help</li> */}
                                        <hr />
                                        <li className={`list ${activeItem === 'logout' ? 'active' : ''}`}
                                            onClick={() => handleItemClick('logout')}><LogoutIco />Logout</li>
                                    </ul>
                                </div>
                            </div>)}

                    {/* <!-- Blog Section --> */}
                    <div className="col-sm-12 col-md-10 col-lg-10" onClick={() => setIsMenuOpen(false)}>
                        <Ocesha_header handleItemClick={handleItemClick} />
                        <div className="overlay-container" style={{ marginTop: '1%', borderTop: '1px solid grey', padding: '2%' }}>
                            {(activeItem === 'blogs' || activeItem === 'upgrade') ? <Blogs_Section />
                                : activeItem === 'dashboard' ? <OceshaDashboard />
                                    : activeItem === 'images' ? <Image_listing />
                                        : activeItem === 'settings' ? <Usersetting />
                                            : activeItem === 'billing' ? <Billing />
                                                : ''}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Popups */}
            <Modal
                open={helperPopup}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    {/* <video
                        controls
                        style={{ width: '100%' }}
                        src={helpervideosrc}
                        autoPlay
                    /> */}
                    <iframe
                        width="100%"
                        height="500"
                        src={helpervideosrc}
                        title="Help Video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                    <Stack flexDirection="row">
                        <Button
                            onClick={() => setHelperPopup(false)}
                            variant="contained"
                            sx={{ mr: 2, marginTop: '15%' }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={upgradeModal}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "60%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "55%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    <Typography style={{ textAlign: 'center' }}><InfoIcon sx={{ color: '#ffcc00' }} /><br />This feature is not included in your plan.  Please upgrade to have access to this feature.</Typography>

                    <Stack flexDirection="row" sx={{ display: 'flex', gap: '10px', marginTop: '1%' }}>

                        <Button
                            variant="contained"
                            onClick={() => {
                                const value = {
                                    _id: "",
                                    product_name: "CodeGenie",
                                    product_module: genieSelector.module ? genieSelector.module : "",
                                    userid: "",
                                    productid: ""
                                };
                                getProduct(value);
                                localStorage.setItem('fromUpgrade', 'true');
                                navigate('/pricing', { state: { campIdFromUpgrade: selectedCampaign, from_page: 'pricing', fromNonSubscribed: 'true' } });
                            }}>
                            {'Upgrade'}
                        </Button>

                        <Button
                            onClick={() => setUpgradeModal(false)}
                            variant="contained"
                        // sx={{ mr: 2, marginTop: '15%' }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            {/*  */}
        </>
    )
}

export default Ocesha_new_design;